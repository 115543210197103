<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import Content from "./GartenKultur-Website-Content.vue"
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <div class="p-fluid formgrid grid">
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
            <template #header>
                <i class="pi pi-book mr-2"></i>
                <span class="mr-3">Inhalte</span>
            </template>
            <Content />
            </TabPanel>
        </TabView>
    </div>
</template>